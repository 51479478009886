<template>
  <section class="home-block">
    <div class="container">
      <SlidesScroll>
        <GalleryItem
          v-for="item in content"
          :key="item.title"
          :item="item"
          :no-popup-mobile="true"
        />
      </SlidesScroll>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import GalleryItem from "@/components/GalleryItem.vue";
export default {
  components: {
    SlidesScroll,
    GalleryItem,
  },
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.home-block {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .home-block {
    padding-top: 0;
  }
}
</style>
